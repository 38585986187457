import React, { useState } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { FormGroup, Label, Input, Button } from "reactstrap"
import FileUploader from "./FileUploader"
import { validateFields, getBase64 } from "./validation"
import { StyledForm, MyStyledForm2 } from "./StyledForm"
import { Link } from "gatsby"
import processName from "../../utils/processName"

const MyForm2 = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    postal: "",
    phone: "",
    items: "",
    comment: "",
    imagesUrls: [],
    accept: false,
  })
  const [images, setImages] = useState([])
  const [errorMsg, setErrorMsg] = useState({
    name: null,
    email: null,
    phone: null,
    address: null,
    postal: null,
    items: null,
    comment: null,
    imagesUrls: null,
    accept: null,
  })

  const [submitted, setSubmitted] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  // translation
  const { t } = useTranslation()
  let errorMsgs = {}
  errorMsgs.name = t("common:form.error.name")
  errorMsgs.email = t("common:form.error.email")
  errorMsgs.phone = t("common:form.error.phone")
  errorMsgs.address = t("common:form.error.address")
  errorMsgs.postal = t("common:form.error.postal")
  errorMsgs.accept = `${t("common:form.error.accept")} ${t(
    "common:form.error.privacy"
  )}`

  const base64ToFile = dataurl => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  const handleFileSelectionChange = (e, idx) => {
    if (e.target.type === "file") {
      const existingImgObjIndex = images.findIndex(
        imgObj => imgObj.index === idx
      )
      if (existingImgObjIndex >= 0) {
        let newImages = [...images]
        newImages[existingImgObjIndex].fileObj = e.target.files[0]
        setImages(newImages)
      } else {
        let newImages = [...images]
        newImages.push({
          index: idx,
          fileObj: e.target.files[0],
        })
        setImages(newImages)
      }
      // console.log("images====>", images);
    }
  }

  const handleChange = async e => {
    const updateKey = e.target.name
    const updateValue = e.target.value
    let newFormData
    if (updateKey === "accept") {
      const updateChecked = e.target.checked
      newFormData = { ...formData, [updateKey]: updateChecked }
    } else {
      newFormData = { ...formData, [updateKey]: updateValue }
    }

    setFormData(newFormData)
    const isFormValid = validateFields(setErrorMsg, newFormData, errorMsgs)
    setBtnDisabled(!isFormValid)
  }

  const submitForm = async e => {
    e.preventDefault()

    if (validateFields(setErrorMsg, formData, errorMsgs)) {
      try {
        // disables the btn to prevent double submission
        setBtnDisabled(true)

        // * * * * Generate image URLs on S3 before upload  * * * *
        let imageUrlRequests = images.map(img => {
          return {
            index: img.index,
            mime: img.fileObj.type,
          }
        })

        const uploadUrlsResponse = await axios.post(
          "/.netlify/functions/generate-url",
          {
            urlRequests: imageUrlRequests,
          }
        )
        // * * * * UPLOAD - take each generated Url, find the corresponding image from the images array
        // Convert the image to base64
        for (let i = 0; i < uploadUrlsResponse.data.urls.length; i++) {
          const file = images.find(
            image => image.index === uploadUrlsResponse.data.urls[i].index
          ).fileObj
          let base64Image = await getBase64(file)
          const uploadResponse = await axios.put(
            uploadUrlsResponse.data.urls[i].url,
            base64ToFile(base64Image)
          )
        }

        //  * * * * get the Urls and add it to newFormData
        let newFormData = { ...formData }
        newFormData.imagesUrls = uploadUrlsResponse.data.urls.map(
          r => r.url.split("?")[0]
        )

        //  * * * * Add submission details to google sheet
        const uploadToSheetsResponse = await axios.post(
          "/.netlify/functions/set-sheet-row-lomtalanitas",
          newFormData
        )

        console.log(
          "Uloaded to google sheet, response: ",
          uploadToSheetsResponse.status
        )

        //  * * * * SEND CONFIRMATION EMAILS - attach the Url of uploaded images to newFormData
        const response = await axios.post(
          "/.netlify/functions/form-email-lomtalanitas",
          newFormData
        )
        console.log("Email sent, response: ", response.status)

        //  * * * * SUBSCRIBE TO NEWSLETTER LIST - attach the Url of uploaded images to newFormData

        const names = processName(formData.name)
        let newsletterData = {
          email: `${formData.email}`,
          first_name: `${names.firstName}`,
          last_name: `${names.lastName}`,
          phone_number: `${formData.phone}`,
          address_line_1: `${formData.address}`,
          postal_code: `${formData.postal}`,
          custom_fields: {
            source: "felajanlott",
            origin_site: "https://cseriti.hu",
            leadActions: true,
            purchaseActions: false,
            accept: true,
          },
        }

        const newsletterResponse = await axios.post(
          "/.netlify/functions/newsletter-signup",
          newsletterData
        )
        console.log("Newsletter signup successful: ", newsletterResponse.status)
      } catch (e) {
        // handle errors
        console.log("erros happened: ", e)
      }

      // close
      setSubmitted(true)
      setImages([])
      setTimeout(() => {
        setFormData({
          name: "",
          email: "",
          phone: "",
          items: "",
          comment: "",
          address: "",
          postal: "",
          accept: false,
        })
        setErrorMsg({
          name: null,
          email: null,
          phone: null,
          address: null,
          postal: null,
          imagesUrls: [null, null, null, null, null, null],
          accept: null,
        })
        setSubmitted(false)
        setBtnDisabled(false)
      }, 8000)
    }
  }

  return (
    <MyStyledForm2 xs={12} md={8} lg={6}>
      <div>
        {submitted && (
          <span style={{ textTransform: "uppercase", fontSize: "2rem" }}>
            {`Kedves ${formData.name}!`}
          </span>
        )}
      </div>
      <div>
        {submitted ? (
          <div>
            <h2>Űrlap elküldve, köszönjük!</h2>
            <p>
              E-mail címedre visszaigazolást küldtünk. Amennyiben a
              visszaigazolás 1 órán belül nem érkezik meg, kérjük, jelezd
              elérhetőségeink valamelyikén!
            </p>
          </div>
        ) : (
          <StyledForm method="post" action="/thank-you">
            <input type="hidden" name="form-name" value="felajanlott" />
            {/* Bot submission honeypot */}
            <p hidden>
              <label>
                Don't fill this out:{" "}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <FormGroup className="user-data">
              <Label>
                {t(`common:form.name`)} <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="name"
                value={formData["name"]}
                onChange={handleChange}
              />
              <span className="text-danger d-block">{errorMsg["name"]}</span>
              <Label>
                {t(`common:form.email`)} <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="email"
                value={formData["email"]}
                onChange={handleChange}
              />
              <span className="text-danger d-block">{errorMsg["email"]}</span>
              <Label>
                {t(`common:form.phone`)} <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="phone"
                value={formData["phone"]}
                onChange={handleChange}
              />
              <span className="text-danger d-block">{errorMsg["phone"]}</span>
              <Label>
                {t(`common:form.address`)}{" "}
                <span className="text-danger">*</span>
              </Label>
              <div className="d-flex">
                <div className="flex-grow-0">
                  <Label>
                    {t(`common:form.postal`)}{" "}
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="postal"
                    value={formData["postal"]}
                    onChange={handleChange}
                  />
                  <span className="text-danger d-block">
                    {errorMsg["postal"]}
                  </span>
                </div>
                <div className="flex-grow-1">
                  <Label>
                    {t(`common:form.street`)}{" "}
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="address"
                    value={formData["address"]}
                    onChange={handleChange}
                  />
                  <span className="text-danger d-block">
                    {errorMsg["address"]}
                  </span>
                </div>
              </div>
              <Label>{t(`common:form.items`)} </Label>
              <Input
                type="textarea"
                name="items"
                value={formData["items"]}
                onChange={handleChange}
              />
              <Label>{t(`common:form.comment`)} </Label>
              <Input
                type="textarea"
                name="comment"
                value={formData["comment"]}
                onChange={handleChange}
              />
            </FormGroup>
            <Row>
              <Col xs="12">
                <Label>
                  {t("common:form.pics")}
                  <br />
                  <span style={{ fontSize: ".8rem" }}>Maximum 6MB / kép</span>
                </Label>
              </Col>

              {[1, 2, 3, 4, 5, 6].map(i => (
                <Col xs="6" key={i}>
                  <FileUploader
                    onFileSelectSuccess={event =>
                      handleFileSelectionChange(event, i)
                    }
                    onFileSelectError={({ error }) =>
                      setErrorMsg(prevState => {
                        return { ...prevState, imagesUrls: error }
                      })
                    }
                    fieldName={`file${i}`}
                    fieldTranslation={t("common:form.chooseImg")}
                    errorMessage={errorMsg.imagesUrls}
                  />
                </Col>
              ))}
            </Row>
            <Row>
              <Col>
                <Input
                  type="checkbox"
                  name="accept"
                  className="ml-2 d-inline-block"
                  value={formData["accept"]}
                  onChange={handleChange}
                />
                <Label className="ml-5 d-inline-block">
                  <Link
                    style={{ textDecoration: "underline" }}
                    to="/hu/adatvedelem"
                  >
                    {t(`common:form.error.privacy`)}{" "}
                  </Link>
                  {t(`common:form.error.accept`)}
                </Label>
              </Col>
            </Row>
          </StyledForm>
        )}
      </div>
      {submitted ? (
        ""
      ) : (
        <div>
          {/* {btnDisabled ? <p>{t("common:form.sendEmail")}</p> : ""} */}
          <Button
            disabled={btnDisabled}
            color="primary"
            onClick={submitForm}
            className="text-uppercase"
          >
            {t("common:form.sendButton")}
          </Button>{" "}
        </div>
      )}
    </MyStyledForm2>
  )
}

export default MyForm2
